import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import CoqIcon from "../../../static/Coq.svg"

const HomeOreillerSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  background: #1b2a56;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;

  @media (max-width: 550px) {
    padding: 0px;
  }
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const ProductLinkTitle = styled.span`
  display: none;
`

const ShopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 20px;

  @media (max-width: 550px) {
    flex-direction: column-reverse;
    padding: 0px;
  }
`

const ContentBox = styled.div`
  background: ${props => props.theme.saleColor.tagGradient};
  border-radius: 25px 0px;
  padding: 30px 30px;
  max-width: 600px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  @media (max-width: 550px) {
    padding: 20px 20px 25px;
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    top: 50%;
    transform: translate(0%, -50%);
    width: calc(100% - 40px);
    // background: rgba(11, 34, 70, 0.6);
  }
`

const TopText = styled.div`
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 21px;
  line-height: 1;
  color: #262626;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
  border-radius: 10px 0px;
  padding: 5px 10px;
  margin-bottom: 15px;
  text-align: center;
`

const Title = styled.h2`
  font-size: 30px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-family: "Museo";
  font-weight: 900;
  margin: 0px;
  // text-transform: uppercase;
  background: ${props => props.theme.saleColor.bgTagColor};
  color: ${props => props.theme.saleColor.textColor};
  // border: ${props => "2px solid" + props.theme.saleColor.borderColor};
  border-radius: 13px 0px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  // border: 1px solid #24b2db;

  span {
    font-size: 13px;
    margin-left: 5px;
  }

  @media (max-width: 550px) {
    font-size: 28px;
    text-align: center;
  }

  @media (max-width: 360px) {
    font-size: 24px;
  }
`

const LastDaysText = styled.h2`
  font-size: 36px;
  line-height: 1.1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  text-shadow: 0px 1.74031px 8.70155px #f9a326, 0.5px 0px 4px #f9a326;
  margin: 10px 0px 0px;
`

const TextWrapper = styled.div`
  margin: 10px 0px;
`

const TextBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const Text = styled.p`
  font-size: 18px;
  line-height: 1.1;
  font-family: "Museo";
  font-weight: bold;
  color: #262626;
  color: ${props => props.theme.saleColor.textColor};
  margin: 15px 0px;
  display: flex;
  align-items: center;

  > span:first-of-type {
    font-size: 24px;
    line-height: 1;
    font-family: "Museo";
    font-weight: 900;
    background: ${props => props.theme.saleColor.bgTagColor};
    color: ${props => props.theme.saleColor.textColor};
    border-radius: 10px 0px;
    padding: 4px 7px;
    margin: 0px 7px;

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  span span {
    @media (max-width: 550px) {
      display: block;
    }
  }

  @media (max-width: 420px) {
    font-size: 16px;
  }
`

const DesktopImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;

  @media (max-width: 550px) {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 100%;
  top: 0;
  object-fit: cover;
  display: none;

  @media (max-width: 550px) {
    display: block;
    margin: 0px;
  }
`

const TopImage = styled.img`
  max-width: 30px;
  margin: 0px;
  position: absolute;
  top: -15px;
  left: 35%;
`

const LeftImage = styled.img`
  max-width: 70px;
  margin: 0px;
  position: absolute;
  top: 50%;
  left: -30px;
`

const RightImage = styled.img`
  max-width: 30px;
  margin: 0px;
  position: absolute;
  top: 65%;
  right: 20px;
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : "row")};
  align-items: ${props => (props.align ? props.align : "center")};
  justify-content: ${props =>
    props.justify ? props.justify : "space-between;"};
  z-index: 1;
  position: relative;

  @media (max-width: 550px) {
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white.base};
  // color: #262626;
  font-size: 18px;
  padding: 12px 30px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  border-radius: 25px 0px;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    // background: linear-gradient(
    //   61.74deg,
    //   #ff7881 -4.05%,
    //   #ffab61 52.44%,
    //   #ffe775 91.92%
    // );
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    // background: linear-gradient(
    //   61.74deg,
    //   #ff7881 -4.05%,
    //   #ffab61 52.44%,
    //   #ffe775 91.92%
    // );
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    display: none;
  }
`

const MobileStyledLink = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  // background: linear-gradient(
  //   61.74deg,
  //   #ff7881 -4.05%,
  //   #ffab61 52.44%,
  //   #ffe775 91.92%
  // );
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white.base};
  // color: ${props => props.theme.colors.button.black};
  font-size: 18px;
  padding: 12px 30px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  border-radius: 25px 0px;
  display: none;

  :hover {
    color: #262626;
    // background: ${props => props.theme.gradient.buttonGradient1};
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    // background: ${props => props.theme.gradient.buttonGradient1};
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    display: block;
  }
`

const ClockImage = styled.img`
  width: 100%;
  max-width: 20px;
  margin: 0px 0px 0px 5px;
`

const HomeOreillerSection = ({ intl, data }) => {
  return (
    data && (
      <HomeOreillerSectionWrapper>
        {data.button_text && data.button_url && (
          <ProductLink to={data.button_url}>
            <ProductLinkTitle>{data.button_text}</ProductLinkTitle>
          </ProductLink>
        )}
        {data.image && (
          <DesktopImage
            className="lazyload"
            data-src={data.image}
            alt={data.alt ? data.alt : ""}
          />
        )}
        <ShopContainer>
          {data.mobile_image && (
            <MobileImage
              className="lazyload"
              data-src={data.mobile_image}
              alt={data.alt ? data.alt : ""}
            />
          )}
          <ContentBox>
            {data.button_text && data.button_url && (
              <ProductLink to={data.button_url}>
                <ProductLinkTitle>{data.button_text}</ProductLinkTitle>
              </ProductLink>
            )}
            {/* <TopImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/84bcd98c-72ac-4af5-9e70-95be8d153ed0.png"
            />
            <LeftImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/84bcd98c-72ac-4af5-9e70-95be8d153ed0.png"
            />
            <RightImage
              className="lazyload"
              data-src="https://static.percko.com/uploads/84bcd98c-72ac-4af5-9e70-95be8d153ed0.png"
            /> */}
            {data.top_text && (
              <TopText
                dangerouslySetInnerHTML={{
                  __html: data.top_text,
                }}
              />
            )}

            {/* {data.title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )} */}

            {data.title && (
              <Title>
                Offre limitée
                {/* <ClockImage src={CoqIcon} /> */}
              </Title>
            )}

            {/* <LastDaysText
              dangerouslySetInnerHTML={{
                __html: "DERNIERS JOURS",
              }}
            /> */}

            {data.text && (
              <TextWrapper>
                <TextBox>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data.text,
                    }}
                  />
                </TextBox>
              </TextWrapper>
            )}
            {data.button_text && data.button_url && (
              <FlexWrapper>
                <StyledLink to={data.button_url}>{data.button_text}</StyledLink>
                <MobileStyledLink to={data.button_url}>
                  Je découvre
                </MobileStyledLink>
              </FlexWrapper>
            )}
          </ContentBox>
        </ShopContainer>
      </HomeOreillerSectionWrapper>
    )
  )
}

export default injectIntl(HomeOreillerSection)
